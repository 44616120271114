import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Input, Radio } from 'antd'
import { getA9tokenAddress } from 'utils/addressHelpers'
import { useWeb3React } from '@web3-react/core'

const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`

const BurnBnbBody = styled.div`
  .burnPng {
    width: 65%;
    margin: 0px auto;
    padding: 10px 0px;
  }

  .burnContent {
    width: 90%;
    margin: 0px auto;
  }
  .ant-radio-group-solid
    :where(.css-dev-only-do-not-override-j0nf2s).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #000;
    background: #ffc964;
    border-color: #ffc964;
  }
  :where(.css-dev-only-do-not-override-j0nf2s).ant-radio-button-wrapper {
    background: #fff1df;
  }
  :where(.css-dev-only-do-not-override-j0nf2s).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #ffc964;
  }

  .widt {
    width: 100%;
    margin: 20px 0px;
    padding: 15px;
    background: #fff;
    border-radius: 5px;
    .burn {
      > div {
        line-height: 32px;
        border-bottom: 1px solid #fff1df;
        font-weight: 600;
      }
    }
  }

  .button {
    > button {
      margin: 8px 0px;
      width: 100%;
    }
  }
`

const BurnRadioGroup = styled(Radio.Group)`
  width: 100%;
  display: flex;
  text-align: center;
  font-weight: 600;
  line-height: 28px;
  .ant-radio-group-solid
    :where(.css-dev-only-do-not-override-j0nf2s).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #000;
    background: #ffc964;
    border-color: #ffc964;
  }

  .ant-radio-button-wrapper {
    flex: 1;
    height: 100%;
    :where(.css-dev-only-do-not-override-j0nf2s).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: #ffc964;
    }
  }
`

const LockInput = styled(Input)`
  height: 60px;
  background: #f3f3f3;
  border-radius: 5px;
  border: none;
  padding: 4px 10px;
  > input {
    background-color: #f3f3f3;
    :focus,
    :focus-within,
    :hover {
      background-color: #f3f3f3;
    }
  }
  :focus,
  :focus-within,
  :hover {
    background-color: #f3f3f3;
  }
`
const Max = styled.div``

const Building = () => {
  const { chainId, account } = useWeb3React()

  return (
    <BurnBnbBody>
      <div className="burnPng">
        <img src="/imgs/home.png" alt="" />
      </div>
      <div className="burnContent">
        <div style={{ margin: '10px 0px', lineHeight: '18px' }}>
          <p style={{ fontSize: '24px', fontWeight: 600, margin: '10px 0px' }}>$A9 代币经济学</p>
          <p style={{ margin: '10px 0px' }}>BNB燃烧池享受交易税1.5%分红，2倍出局，收益随时可以领取</p>
          <p style={{ margin: '10px 0px' }}>燃烧的BNB分配:</p>
          <p style={{ margin: '10px 0px' }}>
            总供应量：9999
            <br />
            ·交易税：3%
            <br />
            （1%销毁 0.5%筑底分红 1.5%燃烧分红）
            <br />
            代币合约: {getA9tokenAddress(chainId)}
          </p>
        </div>

        <div style={{ margin: '10px 0px', lineHeight: '18px' }}>
          <p style={{ fontSize: '24px', fontWeight: 600, margin: '10px 0px' }}>$A9 五大优势</p>
          <p style={{ margin: '10px 0px' }}>
            <p>·公平发射</p>
            <p>无预售、无私募、无预留、无白名单</p>
          </p>
          <p style={{ margin: '10px 0px' }}>
            <p>·无限通缩</p>
            <p>1、交易税销毁；2、主动燃烧分红销毁；3、筑底共创退出100%销毁。</p>
          </p>
          <p style={{ margin: '10px 0px' }}>
            <p>·共建共赢</p>
            <p>投入燃烧代币实现两倍出局，凝聚强大共识群体，实现无限复投复利。</p>
          </p>
          <p style={{ margin: '10px 0px' }}>
            <p>·增值潜力大</p>
            <p>初始市值约1200美金，百倍打底千倍起航，市场空间大。</p>
          </p>
          <p style={{ margin: '10px 0px' }}>
            <p>·社区共创</p>
            <p>初始市值约1200美金，百倍打底千倍起航，市场空间大。</p>
          </p>
        </div>
      </div>
    </BurnBnbBody>
  )
}

export default Building
