import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Input, Radio } from 'antd'
import { Button, Flex } from 'pancake-uikit'
import {
  fetchA9BuildingUserData,
  fetchA9InvestmentData,
  fetchA9InvestmentUserData,
  setA9BuildingUserData,
  setA9InvestmentData,
  setA9InvestmentUserData,
} from 'state/a9'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useAppDispatch, useAppSelector } from 'state'
import { useClaim, useInvolvedaLiquidity, useWithdrawLiquidity } from './hooks'

const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`

const BurnBnbBody = styled.div`
  .burnPng {
    width: 65%;
    margin: 0px auto;
    padding: 10px 0px;
  }

  .burnContent {
    width: 90%;
    margin: 0px auto;
  }
  .ant-radio-group-solid
    :where(.css-dev-only-do-not-override-j0nf2s).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #000;
    background: #ffc964;
    border-color: #ffc964;
  }
  :where(.css-dev-only-do-not-override-j0nf2s).ant-radio-button-wrapper {
    background: #fff1df;
  }
  :where(.css-dev-only-do-not-override-j0nf2s).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #ffc964;
  }

  .widt {
    width: 100%;
    margin: 20px 0px;
    padding: 15px;
    background: #fff;
    border-radius: 5px;
    .burn {
      > div {
        line-height: 32px;
        border-bottom: 1px solid #fff1df;
        font-weight: 600;
      }
    }
  }

  .button {
    > button {
      margin: 8px 0px;
      width: 100%;
    }
  }
`

const BurnRadioGroup = styled(Radio.Group)`
  width: 100%;
  display: flex;
  text-align: center;
  font-weight: 600;
  line-height: 28px;
  .ant-radio-group-solid
    :where(.css-dev-only-do-not-override-j0nf2s).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #000;
    background: #ffc964;
    border-color: #ffc964;
  }

  .ant-radio-button-wrapper {
    flex: 1;
    height: 100%;
    :where(.css-dev-only-do-not-override-j0nf2s).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: #ffc964;
    }
  }
`

const LockInput = styled(Input)`
  height: 60px;
  background: #f3f3f3;
  border-radius: 5px;
  border: none;
  padding: 4px 10px;
  > input {
    background-color: #f3f3f3;
    :focus,
    :focus-within,
    :hover {
      background-color: #f3f3f3;
    }
  }
  :focus,
  :focus-within,
  :hover {
    background-color: #f3f3f3;
  }
`
const Max = styled.div``

const Building = () => {
  const dispatch = useAppDispatch()
  const { chainId, account } = useWeb3React()
  const store = useAppSelector((state) => state.a9)

  useEffect(() => {
    const init = async () => {
      if (account) {
        const reult = await fetchA9BuildingUserData(chainId, account)
        console.log(reult)
        dispatch(setA9BuildingUserData(reult))
      }
    }
    init()
  }, [chainId, account, dispatch])

  const { isLoading: isInvolvedaLiquidityLoading, involvedaLiquidity } = useInvolvedaLiquidity()
  const { isLoading: isWithdrawLiquidityLoading, withdrawLiquidity } = useWithdrawLiquidity()
  const { isLoading: isClaimLoading, claim } = useClaim()

  const involveError = useMemo(() => {
    if (store?.user?.a9Building?.isBuilding) return '已参与'
    if (store?.user?.a9Building?.buildingLimit === 0) return '结束'
    if (Number(store.user?.balance) < Number(0.04)) return '余额不足'
    return ''
  }, [store])

  const claimError = useMemo(() => {
    if (!store?.user?.a9Building?.isBuilding) return '未参与'
    if (store?.user?.a9Building?.pendingBNB === 0) return '没有收益'
    return ''
  }, [store])

  const withdrawError = useMemo(() => {
    if (!store?.user?.a9Building?.isBuilding) return '未参与'
    return ''
  }, [store])

  return (
    <BurnBnbBody>
      <div className="burnPng">
        <img src="/imgs/building.png" alt="" />
      </div>
      <div className="burnContent">
        <div className="widt">
          <Flex flexDirection="column" className="burn">
            <div>
              全网分红: <span>{store?.user?.a9Building?.UnRevenueBnb || 0}</span>
            </div>
            <div>
              积累分红: <span>{store?.user?.a9Building?.claimReward || 0}</span>
            </div>
            <div>
              我的 LP: <span>{store?.user?.a9Building?.pendingLP || 0}</span>
            </div>
            <div>
              待领取分红: <span>{store?.user?.a9Building?.pendingBNB || 0}</span>
            </div>
          </Flex>
        </div>
        <div className="button">
          <Button
            disabled={!!involveError || isInvolvedaLiquidityLoading}
            style={{ flex: '1', borderRadius: '5px', background: '#7579ff', boxShadow: 'none' }}
            onClick={involvedaLiquidity}
          >
            {isInvolvedaLiquidityLoading ? <Dots>加入共创</Dots> : involveError || '加入共创'}
          </Button>
          <Button
            disabled={!!claimError || isClaimLoading}
            style={{ flex: '1', borderRadius: '5px', background: '#7579ff', boxShadow: 'none' }}
            onClick={claim}
          >
            {isClaimLoading ? <Dots>领取分红</Dots> : claimError || '领取分红'}
          </Button>
          <Button
            disabled={!!withdrawError || isWithdrawLiquidityLoading}
            style={{ flex: '1', borderRadius: '5px', background: '#7579ff', boxShadow: 'none' }}
            onClick={withdrawLiquidity}
          >
            {isWithdrawLiquidityLoading ? <Dots>退出共创</Dots> : withdrawError || '退出共创'}
          </Button>
        </div>

        <div style={{ margin: '10px 0px', lineHeight: '18px' }}>
          <p style={{ fontSize: '24px', fontWeight: 600, margin: '10px 0px' }}>筑底共创规则</p>
          <p style={{ margin: '10px 0px' }}>
            初始底池3个BNB+9999枚A9代币，通过筑底共创每人0.04个BNB，共150人累计6个BNB，由DAPP合约统一购买≈2.19个BNB的代币，与剩余BNB组成LP，平分给150个地址；
          </p>
          <p style={{ margin: '10px 0px' }}>参与筑底的地址可永久享受交易税的0.5%BNB分红，收益可随时领取；</p>
          <p style={{ margin: '10px 0px' }}>
            参与筑底的地址可随时退出，退出后所属LP内的BNB返还给用户，A9代币100%销毁；
            由还未退出的用户继续分享交易税分红。
          </p>
        </div>
      </div>
    </BurnBnbBody>
  )
}

export default Building
