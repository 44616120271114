import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'
import { ChainId } from 'pancake-sdk'

export const getAddress = (address: Address): string => {
  const chainId = parseInt(process.env.REACT_APP_CHAIN_ID as string, 10)
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getMulticallAddress = (chainId?: ChainId) => {
  return  chainId ? addresses.Multicall[chainId] : getAddress(addresses.Multicall)
}


export const getPresaleIdoAddress = (chainId?: ChainId) => {
  return chainId ? addresses.PresaleIdo[chainId] : getAddress(addresses.PresaleIdo)
}

export const getUsdtAddress = (chainId?: ChainId) => {
  return chainId ? addresses.Usdt[chainId] : getAddress(addresses.Usdt)
}

export const getFltAddress = (chainId?: ChainId) => {
  return chainId ? addresses.Flt[chainId] : getAddress(addresses.Flt)
}

export const getA9BuildingAddress = (chainId?: ChainId) => {
  return chainId ? addresses.A9Building[chainId] : getAddress(addresses.A9Building)
}

export const getA9InvestmentAddress = (chainId?: ChainId) => {
  return chainId ? addresses.A9Investment[chainId] : getAddress(addresses.A9Investment)
}
export const getA9tokenAddress = (chainId?: ChainId) => {
  return chainId ? addresses.A9token[chainId] : getAddress(addresses.A9token)
}