import { NETWORK_CHAIN_ID } from 'connectors'
import { ethers } from 'ethers'
import { getMulticallContract } from 'utils/contractHelpers'
import { DEFAULT_PROVIDERS } from 'utils/providers'

export interface Call {
  address: string // Address of the contract
  name: string // Function name on the contract (example: balanceOf)
  params?: any[] // Function params
}

interface MulticallOptions {
  requireSuccess?: boolean
}

export const multicall = async <T = any>(chainId: number, abi: any[], calls: Call[]): Promise<T> => {
  const multi = getMulticallContract(chainId, DEFAULT_PROVIDERS[chainId || NETWORK_CHAIN_ID])
  const itf = new ethers.utils.Interface(abi)

  const calldata = calls.map((call) => ({
    target: call.address.toLowerCase(),
    callData: itf.encodeFunctionData(call.name, call.params),
  }))

  const { returnData } = await multi.aggregate(calldata)

  const res = returnData.map((call, i) => itf.decodeFunctionResult(calls[i].name, call))

  return res as any
}
