import React from 'react'
import styled from 'styled-components'
import Button from '../../../components/Button/Button'
import { connectorLocalStorageKey, useWalletModal } from '../../WalletModal'
import { ConnectorNames, Login } from '../../WalletModal/types'

interface Props {
  account?: string
  login: Login
  logout: () => void
}

const XDButton = styled(Button)`
  box-sizing: none;
  border-radius: 10px;
  color: #fff;
  border-radius: 5px;
  background: #7579ff;
`

const UserBlock: React.FC<Props> = ({ account, login, logout }) => {
  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(login, logout, account)
  const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : null
  return (
    <div>
      {account ? (
        <XDButton
          scale="sm"
          variant="tertiary"
          onClick={() => {
            logout()
            window.localStorage.removeItem(connectorLocalStorageKey)
          }}
        >
          {accountEllipsis}
        </XDButton>
      ) : (
        <XDButton
          scale="sm"
          onClick={() => {
            login(ConnectorNames.Injected)
            window.localStorage.setItem(connectorLocalStorageKey, ConnectorNames.Injected)
          }}
        >
          Connect
        </XDButton>
      )}
    </div>
  )
}

export default React.memo(UserBlock, (prevProps, nextProps) => prevProps.account === nextProps.account)
