import React, { useState, useEffect, useRef, useMemo } from 'react'
import styled from 'styled-components'
import throttle from 'lodash/throttle'
import { Route, useHistory, Link } from 'react-router-dom'
import { Avatar, Dropdown, Modal, Button, MenuProps, Select, Tabs } from 'antd'
import { useMatchBreakpointsList } from 'pancake-uikit/hooks/useMatchBreakpoints'
import config from 'components/Menu/config'
import { HamburgerCloseIcon, HamburgerIcon } from 'pancake-uikit/components/Svg'
import useI18nText from 'hooks/useI18n'
import { ButtonMenuItem } from 'pancake-uikit/components/ButtonMenu'
// import Container from 'components/Layout/Container'
import Overlay from '../../components/Overlay/Overlay'
import { useMatchBreakpoints } from '../../hooks'
import UserBlock from './components/UserBlock'
import { NavProps } from './types'
import { MENU_HEIGHT, SIDEBAR_WIDTH_REDUCED, SIDEBAR_WIDTH_FULL } from './config'
import './menu.css'
import LangSelector from './components/LangSelector'
import SocialLinks from './components/SocialLinks'

/* eslint-disable */
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  background: #d0f1eb;

  ${({ theme }) => theme.mediaQueries.xs} {
    height: 100%;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    height: 100vh;
  }
`

const SettingsEntry = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 0 8px;
`

const StyledNav = styled.nav<{ showMenu: boolean }>`
  position: fixed;
  /* opacity: 0.5; */
  top: ${({ showMenu }) => (showMenu ? 0 : `-${MENU_HEIGHT}px`)};
  left: 0;
  transition: top 0.2s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-left: 8px; */
  /* padding-right: 16px; */
  width: 100%;
  height: ${MENU_HEIGHT}px;
  background-color: #f3c522;
  /* background: linear-gradient(90deg, #1a041d, #d029fc) !important; */
  border-bottom: solid 2px rgba(133, 133, 133, 0.1);
  z-index: 20;
  transform: translate3d(0, 0, 0);
`

const BodyWrapper = styled.div`
  position: relative;
  display: flex;
`

const Inner = styled.div<{ isPushed: boolean; showMenu: boolean }>`
  flex-grow: 1;
  margin-top: ${({ showMenu }) => (showMenu ? `${MENU_HEIGHT}px` : 0)};
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;

  ${({ theme }) => theme.mediaQueries.nav} {
    /* margin-left: ${({ isPushed }) => `${isPushed ? SIDEBAR_WIDTH_FULL : SIDEBAR_WIDTH_REDUCED}px`}; */
    /* max-width: ${({ isPushed }) => `calc(100% - ${isPushed ? SIDEBAR_WIDTH_FULL : SIDEBAR_WIDTH_REDUCED}px)`}; */
  }
  height: calc(100vh);
`

const MobileOnlyOverlay = styled(Overlay)`
  position: fixed;
  height: 100%;

  ${({ theme }) => theme.mediaQueries.nav} {
    display: none;
  }
`

const MainLogo = styled.div`
  position: absolute;
  ${({ theme }) => theme.mediaQueries.xs} {
    left: 50px;
    width: 80px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    left: 50px;
    width: 250px;
  }
`

const ChainIdLogo = styled.div`
  padding: 3px;
  background: linear-gradient(to bottom, #3b3f42, #f3f3f3);
  list-style: none !important;
  position: relative;
  margin-top: 4px;
  border-radius: 30px;
  height: 36px;
  ${({ theme }) => theme.mediaQueries.xs} {
    margin-right: 5px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-right: 10px;
  }
  li {
    list-style: none;
  }
  .ant-select {
    height: 30px;
    border: none;
    .ant-select-selector {
      background: linear-gradient(90deg, #344754, #d4cfc9);
      border: none;
      border-radius: 30px;
      height: 30px;
    }
  }
  .ant-select-selector {
    border: none;
    color: #fff;
    border-radius: 30px;
    background: #fce19b; // linear-gradient(90deg, #3fa87b, #d1c84d) !important;
    ${({ theme }) => theme.mediaQueries.xs} {
      padding: 0px 0px;
      width: 60px;
    }

    ${({ theme }) => theme.mediaQueries.sm} {
      padding: 0px 10px;
      width: 120px;
    }
  }
`

const CustomModal = styled(Modal)`
  .ant-modal {
    width: 320px !important;
  }
  .ant-modal-content {
    background: linear-gradient(130deg, #f14aa6, #8962fc);
    border-radius: 30px;
  }
  .ant-modal-header {
    background: none;
  }

  .ant-modal-title {
    background: none;
  }
  .ant-modal-body {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
    line-height: 120px;
  }
  .ant-modal-footer {
    button:nth-of-type(2) {
      display: none;
    }
    .ant-btn {
      display: block;
      margin: 0px auto;
      /* background: linear-gradient(to right, #d19b35, #b0741e, #e6ba4c); */
      border-radius: 20px;
      height: 40px;
      padding: 0px 50px;
      color: #000;
      :hover {
        border: none;
        opacity: 0.5;
      }
    }
  }
`

const Header = styled.div`
  width: 100%;
  /* background: #1d3856; */
  padding: 10px 0;

  .IDO1310 {
    height: 100%;
    margin: 0px auto;
    position: relative;
    ${({ theme }) => theme.mediaQueries.xs} {
      width: 100%;
    }
    ${({ theme }) => theme.mediaQueries.sm} {
      width: 1310px;
    }

    .logo {
      ${({ theme }) => theme.mediaQueries.xs} {
        width: 152px;
      }
      ${({ theme }) => theme.mediaQueries.sm} {
        width: 152px;
      }
      /* height: 61px; */
      display: inline-block;

      img {
        height: 48px;
      }
    }
    .basics {
      height: 100%;
      float: right;
      ${({ theme }) => theme.mediaQueries.xs} {
        width: 170px;
      }
      ${({ theme }) => theme.mediaQueries.sm} {
        width: 200px;
      }
      .liebiao {
        right: 250px;
        top: 8px;
        font-size: 23px;
        font-weight: 600;
        top: 65%;
        transform: translate(0%, -50%);
        /* color: #888; */
        ${({ theme }) => theme.mediaQueries.xs} {
          /* display: none; */
          position: none;
          display: none;
        }
        ${({ theme }) => theme.mediaQueries.sm} {
          /* width: 283px; */
          position: absolute;
          display: block;
        }

        .ant-tabs-tab {
          color: #fff;
          font-size: 15px;
          /* top: 50%;
          transform: translate(0%, -50%);
                    position: absolute; */
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: #fea803;
        }
        .ant-tabs-tab-active {
          font-size: 15px;
        }
        .ant-tabs-ink-bar {
          display: none;
        }
      }

      .wallet {
        ${({ theme }) => theme.mediaQueries.xs} {
          float: right;
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translate(0%, -50%);
          button {
            height: 30px;
            font-size: 13px;
          }
        }
        s ${({ theme }) => theme.mediaQueries.sm} {
          float: right;
          position: absolute;
          top: 50%;
          transform: translate(0%, -50%);
          button {
            height: 32px;
            font-size: 16px;
          }
        }
      }

      .tabs {
        position: absolute;
        right: 230px;
        .ant-tabs-tab-btn {
          color: #fff;
        }
      }
      .container {
        overflow: hidden;
        /* margin-right: 10px !important; */
        position: absolute;
        top: 50%;
        transform: translate(0%, -50%);
      }
    }
  }
`

const Menu: React.FC<NavProps> = ({
  account,
  login,
  logout,
  isDark,
  toggleTheme,
  langs,
  setLang,
  currentLang,
  cakePriceUsd,
  links,
  // profile,
  children,
}) => {
  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isPushed, setIsPushed] = useState(!isMobile)
  const [showMenu, setShowMenu] = useState(true)
  const [key, setKey] = useState('5')
  const refPrevOffset = useRef(window.pageYOffset)

  const history = useHistory()
  React.useEffect(() => {
    let isModal = false
    const unlisten = history.listen((location) => {
      console.log(location.pathname)

      config // 当路径变化时，检查路径是否匹配要显示对话框的路径
        .filter((e) => e.modal)
        .map((e) => {
          if (e.href === location.pathname) {
            console.log(e.href)
            isModal = true
          }
        })

      if (isModal) {
        location.pathname = sessionStorage.getItem('location.pathname')
        setIsModalOpen(true)
        isModal = false
      } else {
        setIsModalOpen(false)
        sessionStorage.setItem('location.pathname', location.pathname)
      }
    })
    // 卸载组件时停止监听
    return () => unlisten()
  }, [history])

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight
      const isTopOfPage = currentOffset === 0
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true)
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current) {
          // Has scroll up
          setShowMenu(true)
        } else {
          // Has scroll down
          setShowMenu(false)
        }
      }
      refPrevOffset.current = currentOffset
    }
    const throttledHandleScroll = throttle(handleScroll, 200)

    window.addEventListener('scroll', throttledHandleScroll)
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [])

  // Find the home link if provided
  const homeLink = links.find((link) => link.label === 'Home')

  const breakpoints = useMatchBreakpointsList()

  const LogoSelect = useMemo(() => {
    const itemsText = [
      {
        value: '5',
        label: (
          <>
            <Avatar size={20} src="./chain/core.png" /> Core
          </>
        ),
      },
    ]

    if (!breakpoints.isMobile) {
      return (
        <ChainIdLogo>
          <Select
            defaultValue={key}
            style={{ width: 120 }}
            onChange={(e) => {
              if (e !== '5') {
              }
            }}
            options={itemsText}
          />
        </ChainIdLogo>
      )
    }

    return <div />
  }, [key, breakpoints])

  const t = useI18nText()
  return (
    <>
      <CustomModal
        title={
          <>
            {' '}
            <Avatar src="./favicon.png" style={{ marginRight: '10px' }} size={40} alt="" />
            BabyRabbit Swap
          </>
        }
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
      >
        ComingSoon!
      </CustomModal>
      <Wrapper>
        <StyledNav showMenu={showMenu}>
          {/* <UserBlock account={account} login={login} logout={logout} /> */}
          <SettingsEntry>
            {isMobile && (
              <Dropdown
                menu={{
                  items: config.map(({ label, href }, index) => {
                    return {
                      key: index,
                      label: (
                        <ButtonMenuItem id="swap-nav-link" to={href} as={Link}>
                          {t(label)}
                        </ButtonMenuItem>
                      ),
                    }
                  }),
                }}
                placement="topLeft"
                arrow
              >
                <Button
                  onClick={() => setIsPushed((prevState: boolean) => !prevState)}
                  style={{ background: 'none', boxShadow: 'none', border: 'none' }}
                >
                  {isPushed ? (
                    <HamburgerCloseIcon width="24px" color="#fff" />
                  ) : (
                    <HamburgerIcon width="24px" color="#fff" />
                  )}
                </Button>
              </Dropdown>
            )}
            {!isMobile ? (
              <img style={{ width: '46px' }} src="./favicon.png" alt="" />
            ) : (
              <img style={{ width: '46px' }} src="./favicon.png" alt="" />
            )}
            {!isMobile &&
              config.map(({ label, href }, index) => {
                return (
                  <ButtonMenuItem id="swap-nav-link" to={href} as={Link}>
                    {t(label)}
                  </ButtonMenuItem>
                )
              })}

            {/* <Dropdown menu={{ items }} placement="topLeft" arrow>
              <Button>topLeft</Button>
            </Dropdown> */}
          </SettingsEntry>
          <SettingsEntry>
            {/* <SocialLinks /> */}
            <p style={{ width: '10px' }} />
            <LangSelector currentLang={currentLang} langs={langs} setLang={setLang} />
            <div style={{ width: '15px' }} />
            <UserBlock account={account} login={login} logout={logout} />
          </SettingsEntry>
        </StyledNav>
        <BodyWrapper>
          {/* <Panel
            isPushed={isPushed}
            isMobile={isMobile}
            showMenu={showMenu}
            isDark={isDark}
            toggleTheme={toggleTheme}
            langs={langs}
            setLang={setLang}
            currentLang={currentLang}
            cakePriceUsd={cakePriceUsd}
            pushNav={setIsPushed}
            links={links}
          /> */}
          <Inner isPushed={isPushed} showMenu={showMenu}>
            {children}
          </Inner>
          {/* <MobileOnlyOverlay show={isPushed} onClick={() => setIsPushed(false)} role="presentation" /> */}
        </BodyWrapper>
      </Wrapper>
    </>
  )
}

export default Menu as any
