import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Input, Radio, message } from 'antd'
import { Button, Flex } from 'pancake-uikit'
import {
  fetchA9InvestmentData,
  fetchA9InvestmentUserData,
  setA9InvestmentData,
  setA9InvestmentUserData,
  setRef,
} from 'state/a9'
import copy from 'copy-to-clipboard'
import { useWeb3React } from '@web3-react/core'
import { ethers, utils } from 'ethers'
import { useAppDispatch, useAppSelector } from 'state'
import { useInvest, useWithdraw } from './hook'

const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`

const BurnBnbBody = styled.div`
  .burnPng {
    width: 65%;
    margin: 0px auto;
    padding: 10px 0px;
  }

  .burnContent {
    width: 90%;
    margin: 0px auto;
  }
  .ant-radio-group-solid
    :where(.css-dev-only-do-not-override-j0nf2s).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #000;
    background: #ffc964;
    border-color: #ffc964;
  }
  :where(.css-dev-only-do-not-override-j0nf2s).ant-radio-button-wrapper {
    background: #fff1df;
  }
  :where(.css-dev-only-do-not-override-j0nf2s).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #ffc964;
  }

  .widt {
    width: 100%;
    margin: 20px 0px;
    padding: 15px;
    background: #fff;
    border-radius: 5px;
    .burn {
      > div {
        line-height: 32px;
        border-bottom: 1px solid #fff1df;
        font-weight: 600;
      }
    }
  }
`

const BurnRadioGroup = styled(Radio.Group)`
  width: 100%;
  display: flex;
  text-align: center;
  font-weight: 600;
  line-height: 28px;
  .ant-radio-group-solid
    :where(.css-dev-only-do-not-override-j0nf2s).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #000;
    background: #ffc964;
    border-color: #ffc964;
  }

  .ant-radio-button-wrapper {
    flex: 1;
    height: 100%;
    :where(.css-dev-only-do-not-override-j0nf2s).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: #ffc964;
    }
  }
`

const LockInput = styled(Input)`
  height: 60px;
  background: #f3f3f3;
  border-radius: 5px;
  border: none;
  padding: 4px 10px;
  > input {
    background-color: #f3f3f3;
    :focus,
    :focus-within,
    :hover {
      background-color: #f3f3f3;
    }
  }
  :focus,
  :focus-within,
  :hover {
    background-color: #f3f3f3;
  }
`
const Max = styled.div``

/* eslint-disable */
const BurnBnb: React.FC<any> = () => {
  const dispatch = useAppDispatch()
  const { chainId, account } = useWeb3React()

  const url = window.location.href
  // const ref = props.match.params[0]

  useEffect(() => {
    const refs = url.split('ref=')
    if (refs.length > 1) {
      const ref = refs[1]
      console.log(`utils.isAddress(ref): ${utils.isAddress(ref)}`)
      if (utils.isAddress(ref)) {
        dispatch(setRef(ref))
      }
    }
  }, [url])
  console.log(url.split('ref='))
  const store = useAppSelector((state) => state.a9)
  const [inputAmount, setInputAmount] = useState('')
  const [value4, setValue4] = useState('Apple')
  const optionsWithDisabled = [
    {
      label: '燃烧分红',
      value: 'Apple',
    },
    {
      label: '邀请奖励',
      value: 'Pear',
    },
  ]
  console.log(store)

  const onChange4 = ({ target: { value } }: any) => {
    console.log('radio4 checked', value)
    setValue4(value)
  }

  useEffect(() => {
    const init = async () => {
      const reult = await fetchA9InvestmentData(chainId)
      console.log(reult)
      dispatch(setA9InvestmentData(reult))

      if (account) {
        const userData = await fetchA9InvestmentUserData(chainId, account)
        console.log(userData)
        dispatch(setA9InvestmentUserData(userData))
      }
    }
    init()
  }, [chainId, account, dispatch])
  const _amount = inputAmount?.slice(-1) === '.' ? inputAmount?.slice(0, -1) : inputAmount
  /* eslint-disable */
  const donateError = useMemo(() => {
    if (inputAmount === '') return ''
    else if (store.a9Investment?.startTradeBlock === 0) return '交易未开放'
    else if (
      !(
        Number(_amount) + Number(store?.user?.a9Investment.invest.amount) >=
        Number(store.a9Investment?.userFundingMinLimit)
      )
    )
      return `用户最小投入 ${store.a9Investment?.userFundingMinLimit} BNB`
    else if (
      !(
        Number(_amount) + Number(store?.user?.a9Investment.invest.amount) <=
        Number(store.a9Investment?.userFundingMaxLimit)
      )
    )
      return `用户最大投入 ${store.a9Investment?.userFundingMaxLimit} BNB`
    else if (Number(store.user.balance) < Number(_amount)) return '余额不足'
    return ''
  }, [store, _amount, inputAmount])

  const withdrawError = useMemo(() => {
    // if (inputAmount === '') return ''
    if (store.a9Investment?.startTradeBlock === 0) return '交易未开放'
    else if (Number(store?.user?.a9Investment.invest.amount) === 0) return `未入金`
    else if (Number(store?.user?.a9Investment.invest.pendingReward) === 0) return `没有可领取收益`
    return ''
  }, [store, _amount, inputAmount])

  const { isLoading: isInvestLoading, invest } = useInvest({ inputAmount, ref: store?.ref })
  const { isLoading: isWithdrawLoading, withdraw } = useWithdraw()

  return (
    <BurnBnbBody>
      <div className="burnPng">
        <img src="/imgs/burn.png" alt="" />
      </div>
      {!(value4 === 'Apple') ? (
        <div className="burnContent">
          <BurnRadioGroup
            options={optionsWithDisabled}
            onChange={onChange4}
            value={value4}
            optionType="button"
            buttonStyle="solid"
          />
          <div className="widt">
            <Flex justifyContent="center">
              <p>
                累计奖励:{' '}
                <span>
                  {Number(store?.user?.a9Investment.refRewards || 0) +
                    Number(store?.user?.a9Investment.refTwoRewards || 0)}
                </span>
              </p>
            </Flex>
            <div style={{ padding: '0px 0px' }}>
              <Flex style={{ margin: '20px 0px' }}>
                <Flex flex="1" flexDirection="column">
                  <p>
                    一级推荐: <span>{store?.user?.a9Investment.refAmounts || 0}</span>
                  </p>
                  <p>
                    累计奖励: <span>{store?.user?.a9Investment.refRewards || 0}</span>
                  </p>
                </Flex>
                <Flex flex="1" flexDirection="column">
                  <p>
                    二级推荐: <span>{store?.user?.a9Investment.refTwoAmounts || 0}</span>
                  </p>
                  <p>
                    累计奖励: <span>{store?.user?.a9Investment.refTwoRewards || 0}</span>
                  </p>
                </Flex>
              </Flex>
              <Flex justifyContent="space-between" alignItems="center">
                <div style={{ width: '60%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  邀请链接: {`https://a9a9.com/#/burnBnb?ref=${account}`}
                </div>

                <Button
                  style={{
                    height: '28px',
                    borderRadius: '5px',
                    background: '#7579ff',
                    boxShadow: 'none',
                  }}
                  onClick={() => {
                    copy(`https://a9a9.com/#/burnBnb?ref=${account}`)
                    message.success('copy success')
                  }}
                >
                  copy
                </Button>
              </Flex>
            </div>

            {/* <div
              style={{
                border: '1px solid #000',
                marginTop: '10px',
                padding: '5px',
                textAlign: 'center',
                borderRadius: '5px',
              }}
            >
              <Flex>
                <div style={{ flex: '1', fontWeight: 600 }}>一级用户</div>
                <div style={{ flex: '1', fontWeight: 600 }}>累计燃烧</div>
              </Flex>
              <Flex style={{ margin: '5px 0px', fontSize: '13px' }}>
                <div style={{ flex: '1' }}>一级用户</div>
                <div style={{ flex: '1' }}>累计燃烧</div>
              </Flex>
              <Flex style={{ margin: '5px 0px', fontSize: '13px' }}>
                <div style={{ flex: '1' }}>一级用户</div>
                <div style={{ flex: '1' }}>累计燃烧</div>
              </Flex>
            </div> */}
          </div>
          <div style={{ margin: '10px 0px', lineHeight: '18px' }}>
            <p style={{ fontSize: '24px', fontWeight: 600, margin: '10px 0px' }}>邀请规则</p>
            <p style={{ margin: '10px 0px' }}>
              使用邀请链接推荐燃烧BNB可获得一级燃烧BNB金额的8%、二级燃烧BNB金额的2%；直接发送到推荐人钱包地址。
            </p>
          </div>
        </div>
      ) : (
        <div className="burnContent">
          <BurnRadioGroup
            options={optionsWithDisabled}
            onChange={onChange4}
            value={value4}
            optionType="button"
            buttonStyle="solid"
          />
          <div className="widt">
            <Flex flexDirection="column" className="burn">
              <div>
                全网燃烧: <span>{store.a9Investment?.totalInvestment || 0}</span>
              </div>
              <div>
                全网分红: <span>{store.a9Investment?.unRevenueBnb || 0}</span>
              </div>
              <div>
                我的燃烧: <span>{store.user?.a9Investment.invest.amount || 0}</span>
              </div>
              <div>
                预计分红: <span>{store.user?.a9Investment.invest.unMultiplierAmount || 0}</span>
              </div>
              <div>
                待领取: <span>{store.user?.a9Investment.invest.pendingReward || 0}</span>
              </div>
            </Flex>

            <LockInput
              value={inputAmount}
              onChange={(e: any) => {
                if (!Number.isNaN(e.target.value)) {
                  setInputAmount(e.target.value)
                }
              }}
              suffix={
                <Max
                  style={{ fontWeight: 600, color: '#7579ff', cursor: 'pointer' }}
                  onClick={() => setInputAmount(store.user?.balance)}
                >
                  Max
                </Max>
              }
            />
            <Flex style={{ margin: '10px 0px' }}>
              <Button
                disabled={!!donateError || isInvestLoading}
                style={{ flex: '1', borderRadius: '5px', background: '#7579ff', boxShadow: 'none' }}
                onClick={invest}
              >
                {isInvestLoading ? <Dots>燃烧</Dots> : donateError || '燃烧'}
              </Button>
              <p style={{ width: '10px' }} />
              <Button
                disabled={!!withdrawError || isWithdrawLoading}
                style={{ flex: '1', borderRadius: '5px', background: '#7579ff', boxShadow: 'none' }}
                onClick={withdraw}
              >
                {isWithdrawLoading ? <Dots>领取</Dots> : withdrawError || '领取'}
              </Button>
            </Flex>
          </div>
          <div style={{ margin: '10px 0px', lineHeight: '18px' }}>
            <p style={{ fontSize: '24px', fontWeight: 600, margin: '10px 0px' }}>燃烧分红规则</p>
            <p style={{ margin: '10px 0px' }}>BNB燃烧池享受交易税1.5%分红，2倍出局，收益随时可以领取</p>
            <p style={{ margin: '10px 0px' }}>燃烧的BNB分配:</p>
            <p style={{ margin: '10px 0px' }}>
              -10%邀请奖励一级8%， 二级2% 实时到账BNB
              <br /> -20% 购买代币直接销毁进黑洞 <br />
              -30%燃烧股东分红
              <br /> -40%回流底池
            </p>
          </div>
        </div>
      )}
    </BurnBnbBody>
  )
}

export default BurnBnb
