import { useMemo } from 'react'
import {
  getA9BuildingContract,
  getA9InvestmentContract,
  getERC20Contract,
  getPresaleIdoContract,
} from 'utils/contractHelpers'

import { A9Building, A9Investment, Erc20, PresaleIdo } from 'config/abi/types/index'

import { useActiveWeb3React } from './index'

import { getProviderOrSigner } from '../utils'

export const useERC20Contract = (address: string, withSignerIfPossible = true): Erc20 => {
  const { library, account } = useActiveWeb3React()
  return useMemo(
    () => getERC20Contract(address, withSignerIfPossible ? getProviderOrSigner(library, account) : library),
    [account, address, library, withSignerIfPossible]
  )
}

export const usePresaleIdoContract = (withSignerIfPossible = true): PresaleIdo => {
  const { library, account, chainId } = useActiveWeb3React()
  return useMemo(
    () => getPresaleIdoContract(chainId, withSignerIfPossible ? getProviderOrSigner(library, account) : library),
    [account, library, chainId, withSignerIfPossible]
  )
}

export const useA9BuildingContract = (withSignerIfPossible = true): A9Building => {
  const { library, account, chainId } = useActiveWeb3React()
  return useMemo(
    () => getA9BuildingContract(chainId, withSignerIfPossible ? getProviderOrSigner(library, account) : library),
    [account, library, chainId, withSignerIfPossible]
  )
}

export const useA9InvestmentContract = (withSignerIfPossible = true): A9Investment => {
  const { library, account, chainId } = useActiveWeb3React()
  return useMemo(
    () => getA9InvestmentContract(chainId, withSignerIfPossible ? getProviderOrSigner(library, account) : library),
    [account, library, chainId, withSignerIfPossible]
  )
}

export default {}
