import { MenuEntry } from 'pancake-uikit'

/* eslint-disable */
const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: '燃烧分红',
    icon: 'HomeIcon',
    href: '/burnBnb',
  },
  {
    label: '筑底共创',
    icon: 'HomeIcon',
    href: '/building',
  },
]

export default config
