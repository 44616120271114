import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCallback, useState } from 'react'
import { useAppDispatch } from 'state'
import { formatEther, formatUnits, parseEther } from '@ethersproject/units'
import { useA9BuildingContract, useA9InvestmentContract } from 'hooks/useContract'
import { ethers } from 'ethers'
import { estimateGas } from 'utils/calls/estimateGas'
import {
  fetchA9BuildingUserData,
  fetchA9InvestmentData,
  fetchA9InvestmentUserData,
  setA9BuildingUserData,
  setA9InvestmentData,
  setA9InvestmentUserData,
} from 'state/a9'

export const useInvolvedaLiquidity = () => {
  const { chainId, account } = useActiveWeb3React()
  const [isLoading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const presaleContract = useA9BuildingContract()

  // 加密数据
  const involvedaLiquidity = useCallback(async () => {
    /* eslint-disable */
    try {
      setLoading(true)
      const gasLimit = await estimateGas(presaleContract, 'involvedaLiquidity', [{ value: parseEther('0.04') }], 1000)
      const response = await presaleContract.involvedaLiquidity({
        gasLimit,
        value: parseEther('0.04'),
      })

      const receipt = await response.wait()
      if (receipt.status) {
        const data = await fetchA9BuildingUserData(chainId, account)
        dispatch(setA9BuildingUserData(data))
        // dispatch(fetchCurrentPresaleDataAsync({ chainId }))
        // dispatch(fetchCurrentPresaleUserDataAsync({ chainId, account }))
      }
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
    }
  }, [dispatch, chainId, account, presaleContract])

  return { isLoading, involvedaLiquidity }
}

export const useWithdrawLiquidity = () => {
  const { chainId, account } = useActiveWeb3React()
  const [isLoading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const presaleContract = useA9BuildingContract()

  // 加密数据
  const withdrawLiquidity = useCallback(async () => {
    /* eslint-disable */
    try {
      setLoading(true)
      const gasLimit = await estimateGas(presaleContract, 'withdrawLiquidity', [], 1000)
      const response = await presaleContract.withdrawLiquidity({
        gasLimit,
      })

      const receipt = await response.wait()
      if (receipt.status) {
        const data = await fetchA9BuildingUserData(chainId, account)
        dispatch(setA9BuildingUserData(data))
        // dispatch(fetchCurrentPresaleDataAsync({ chainId }))
        // dispatch(fetchCurrentPresaleUserDataAsync({ chainId, account }))
      }
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
    }
  }, [dispatch, chainId, account, presaleContract])

  return { isLoading, withdrawLiquidity }
}

export const useClaim = () => {
  const { chainId, account } = useActiveWeb3React()
  const [isLoading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const presaleContract = useA9BuildingContract()

  // 加密数据
  const claim = useCallback(async () => {
    /* eslint-disable */
    try {
      setLoading(true)
      const gasLimit = await estimateGas(presaleContract, 'claim', [], 1000)
      const response = await presaleContract.claim({
        gasLimit,
      })

      const receipt = await response.wait()
      if (receipt.status) {
        const data = await fetchA9BuildingUserData(chainId, account)
        dispatch(setA9BuildingUserData(data))
        // dispatch(fetchCurrentPresaleDataAsync({ chainId }))
        // dispatch(fetchCurrentPresaleUserDataAsync({ chainId, account }))
      }
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
    }
  }, [dispatch, chainId, account, presaleContract])

  return { isLoading, claim }
}

 

export default {}
