import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCallback, useState } from 'react'
import { useAppDispatch } from 'state'
import { formatEther, formatUnits, parseEther } from '@ethersproject/units'
import { useA9InvestmentContract } from 'hooks/useContract'
import { ethers } from 'ethers'
import { estimateGas } from 'utils/calls/estimateGas'
import {
  fetchA9InvestmentData,
  fetchA9InvestmentUserData,
  setA9InvestmentData,
  setA9InvestmentUserData,
} from 'state/a9'

export const useInvest = ({ inputAmount, ref }: any) => {
  const { chainId, account } = useActiveWeb3React()
  const [isLoading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const presaleContract = useA9InvestmentContract()

  // 加密数据
  const invest = useCallback(async () => {
    /* eslint-disable */
    try {
      setLoading(true)
      const gasLimit = await estimateGas(
        presaleContract,
        'invest',
        [ref || ethers.constants.AddressZero, { value: parseEther(inputAmount) }],
        1000
      )
      const response = await presaleContract.invest(ref || ethers.constants.AddressZero, {
        gasLimit,
        value: parseEther(inputAmount),
      })

      const receipt = await response.wait()
      if (receipt.status) {
        const data = await fetchA9InvestmentData(chainId)
        const userData = await fetchA9InvestmentUserData(chainId, account)
        dispatch(setA9InvestmentData(data))
        dispatch(setA9InvestmentUserData(userData))
        // dispatch(fetchCurrentPresaleDataAsync({ chainId }))
        // dispatch(fetchCurrentPresaleUserDataAsync({ chainId, account }))
      }
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
    }
  }, [dispatch, chainId, account, presaleContract, ref, inputAmount])

  return { isLoading, invest }
}

export const useWithdraw = () => {
  const { chainId, account } = useActiveWeb3React()
  const [isLoading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const presaleContract = useA9InvestmentContract()

  // 加密数据
  const withdraw = useCallback(async () => {
    /* eslint-disable */
    try {
      setLoading(true)
      const gasLimit = await estimateGas(
        presaleContract,
        'withdraw',
        [ ],
        1000
      )
      const response = await presaleContract.withdraw({gasLimit: gasLimit});

      const receipt = await response.wait()
      if (receipt.status) {
        const data = await fetchA9InvestmentData(chainId)
        const userData = await fetchA9InvestmentUserData(chainId, account)
        dispatch(setA9InvestmentData(data))
        dispatch(setA9InvestmentUserData(userData))
        // dispatch(fetchCurrentPresaleDataAsync({ chainId }))
        // dispatch(fetchCurrentPresaleUserDataAsync({ chainId, account }))
      }
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
    }
  }, [dispatch, chainId, account, presaleContract])

  return { isLoading, withdraw }
}


export default {}
