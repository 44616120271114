import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ethers, utils } from 'ethers'
import {
  getPresaleIdoAddress,
  getFltAddress,
  getUsdtAddress,
  getA9InvestmentAddress,
  getMulticallAddress,
  getA9tokenAddress,
  getA9BuildingAddress,
} from 'utils/addressHelpers'
import Erc20_abi from 'config/abi/Erc20.json'
import A9Investment_abi from 'config/abi/A9Investment.json'
import A9Building_abi from 'config/abi/A9Building.json'
import Multicall_abi from 'config/abi/Multicall.json'
import { Call, multicall } from 'utils/calls/multicall'
import { ChainId } from 'config/constants'
import { NETWORK_CHAIN_ID } from 'connectors'

export const fetchA9InvestmentData = async (chainId: any = NETWORK_CHAIN_ID): Promise<any> => {
  const tokenCalls = [
    'UnRevenueBnb',
    'GlobalUsers',
    'BurnTotalAmounts',
    'totalInvestment',
    'maxProfitMultiplier',
    'fundingLimit',
    'userFundingMinLimit',
    'userFundingMaxLimit',
    'closeLimit',
  ].map((method) => {
    return {
      address: getA9InvestmentAddress(chainId),
      name: method,
      params: [],
    }
  })

  const mainCalls = [
    {
      address: getA9tokenAddress(chainId),
      name: 'startTradeBlock',
      params: [],
    },
  ]

  // 创建合约对象
  const result = await multicall(chainId, A9Investment_abi.concat(Erc20_abi), tokenCalls.concat(mainCalls))
  const [
    [UnRevenueBnb],
    [GlobalUsers],
    [BurnTotalAmounts],
    [totalInvestment],
    [maxProfitMultiplier],
    [fundingLimit],
    [userFundingMinLimit],
    [userFundingMaxLimit],
    [closeLimit],
    [startTradeBlock],
  ] = result

  return {
    A9Investment: {
      unRevenueBnb: Number(utils.formatEther(UnRevenueBnb)).toFixed(3),
      globalUsers: Number(GlobalUsers.toString()),
      burnTotalAmounts: Number(utils.formatEther(BurnTotalAmounts)).toFixed(3),
      totalInvestment: Number(utils.formatEther(totalInvestment)).toFixed(3),
      maxProfitMultiplier: Number(maxProfitMultiplier.toString()),
      fundingLimit: Number(utils.formatEther(fundingLimit)),
      userFundingMinLimit: Number(utils.formatEther(userFundingMinLimit)),
      userFundingMaxLimit: Number(utils.formatEther(userFundingMaxLimit)),
      closeLimit,
      startTradeBlock: Number(startTradeBlock.toString()),
    },
  }
}

export const fetchA9InvestmentUserData = async (chainId: any = NETWORK_CHAIN_ID, account: string): Promise<any> => {
  const tokenCalls = [
    'refs',
    'refAmounts',
    'refTwoAmounts',
    'refRewards',
    'refTwoRewards',
    'userInvestments',
    'pendingBNB',
  ].map((method) => {
    return {
      address: getA9InvestmentAddress(chainId),
      name: method,
      params: [account],
    }
  })

  const mainCalls: any = [
    {
      address: getMulticallAddress(chainId),
      name: 'getEthBalance',
      params: [account],
    },
    {
      address: getA9InvestmentAddress(chainId),
      name: 'getRefLogs',
      params: [account, 0, 100],
    },
  ]

  // 创建合约对象
  const result = await multicall(
    chainId,
    A9Investment_abi.concat(Erc20_abi).concat(Multicall_abi),
    tokenCalls.concat(mainCalls)
  )
  const [
    [refs],
    [refAmounts],
    [refTwoAmounts],
    [refRewards],
    [refTwoRewards],
    userInvestments,
    [pendingBNB],
    [getEthBalance],
    [getRefLogs],
  ] = result

  console.log(userInvestments)
  console.log(getRefLogs)
  return {
    user: {
      balance: Number(utils.formatEther(getEthBalance)).toFixed(3),
      a9Investment: {
        refs: refs.toString() === ethers.constants.AddressZero ? undefined : refs.toString(),
        refAmounts: Number(refAmounts.toString()),
        refTwoAmounts: Number(refTwoAmounts.toString()),
        refRewards: Number(utils.formatEther(refRewards.toString())).toFixed(3),
        refTwoRewards: Number(utils.formatEther(refTwoRewards.toString())).toFixed(3),

        invest: {
          amount: Number(utils.formatEther(userInvestments.amount)).toFixed(3),
          claimReward: Number(utils.formatEther(userInvestments.claimReward)).toFixed(3),
          unMultiplierAmount: Number(utils.formatEther(userInvestments.unMultiplierAmount)).toFixed(3),
          multiplierAmount: Number(utils.formatEther(userInvestments.multiplierAmount)),
          pendingReward: Number(utils.formatEther(pendingBNB)).toFixed(3),
        },
      },
    },
  }
}

export const fetchA9BuildingUserData = async (chainId: any = NETWORK_CHAIN_ID, account: string): Promise<any> => {
  const tokenCalls = ['buildingStates', 'pendingBNB', 'pendingLP', 'getIsbuildingUser'].map((method) => {
    return {
      address: getA9BuildingAddress(chainId),
      name: method,
      params: [account],
    }
  })

  const mainCalls: any = [
    {
      address: getMulticallAddress(chainId),
      name: 'getEthBalance',
      params: [account],
    },
    {
      address: getA9BuildingAddress(chainId),
      name: 'UnRevenueBnb',
      params: [],
    },
    {
      address: getA9BuildingAddress(chainId),
      name: 'buildingLimit',
      params: [],
    },
    {
      address: getA9BuildingAddress(chainId),
      name: 'buildingPrice',
      params: [],
    },
  ]

  // 创建合约对象
  const result = await multicall(
    chainId,
    A9Building_abi.concat(Erc20_abi).concat(Multicall_abi),
    tokenCalls.concat(mainCalls)
  )
  const [
    buildingStates,
    [pendingBNB],
    [pendingLP],
    [getIsbuildingUser],
    [getEthBalance],
    [UnRevenueBnb],
    [buildingLimit],
    [buildingPrice],
  ] = result

  return {
    user: {
      balance: Number(utils.formatEther(getEthBalance)).toFixed(3),
      a9Building: {
        pendingLP: Number(utils.formatEther(pendingLP)).toFixed(3),
        pendingBNB: Number(utils.formatEther(pendingBNB)).toFixed(3),
        UnRevenueBnb: Number(utils.formatEther(UnRevenueBnb)).toFixed(3),
        claimReward: Number(utils.formatEther(buildingStates.claimReward)).toFixed(3),
        buildingLimit: Number(buildingLimit.toString()),
        buildingPrice: Number(utils.formatEther(buildingPrice)).toFixed(3),
        isBuilding: getIsbuildingUser,
      },
    },
  }
}

interface A9State {
  user?: any
  a9Investment?: any
  ref: string
}

const initialState: A9State = {
  ref: undefined
}

export const A9Slice = createSlice({
  name: 'a9',
  initialState,
  reducers: {
    setA9InvestmentData: (state, action) => {
      state.a9Investment = action.payload.A9Investment
      // console.log(action)
    },
    setA9InvestmentUserData: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload.user,
      }
      // console.log(action)
    },
    setA9BuildingUserData: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload.user,
      }
      // console.log(action)
    },
    setRef: (state, action) => {
      state.ref = action.payload
      // console.log(action)
    },
  },
})

// Actions
export const { setA9InvestmentData, setA9InvestmentUserData, setRef,setA9BuildingUserData } = A9Slice.actions

export default A9Slice.reducer
