import { ethers } from 'ethers'
import { simpleRpcProvider } from 'utils/providers'

// Addresses
import {
  getA9BuildingAddress,
  getA9InvestmentAddress,
  getMulticallAddress,
  getPresaleIdoAddress,
} from 'utils/addressHelpers'

// ABI
import Multicall_abi from 'config/abi/Multicall.json'
import MPresaleIdo_abi from 'config/abi/PresaleIdo.json'
import Erc20_abi from 'config/abi/Erc20.json'
import A9Building_abi from 'config/abi/A9Building.json'
import A9Investment_abi from 'config/abi/A9Investment.json'

import {
  A9Building,
  A9Investment,
  Erc20,
  Multicall,
  PresaleIdo
} from 'config/abi/types/index'

// Types


const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer || simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getERC20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(Erc20_abi, address, signer) as Erc20
}

export const getMulticallContract = (chainId?: number , signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(Multicall_abi, getMulticallAddress(chainId), signer) as Multicall
}

export const getPresaleIdoContract = (chainId?: number , signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MPresaleIdo_abi, getPresaleIdoAddress(chainId), signer) as PresaleIdo
}

export const getA9BuildingContract = (chainId?: number , signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(A9Building_abi, getA9BuildingAddress(chainId), signer) as A9Building
}

export const getA9InvestmentContract = (chainId?: number , signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(A9Investment_abi, getA9InvestmentAddress(chainId), signer) as A9Investment
}

export default {}
